.Button {
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: lft-etica, sans-serif;
  padding: 0.625rem 1rem;
  border-radius: 0.625rem;
  border: none;
  width: auto;
  align-self: center;
  cursor: pointer;
  transition: transform .3s ease;
}
.Button-Container{
  margin-top: 12px;
}
.Button_primary {
  background-color: #e00000;
  color: #fff;
}
.Button_primary:hover {
  background-color: #a90000;
  box-shadow: 0 8px 20px #f48484;
  transform: translateY(-3px);
}
.Button_secondary {
  background-color: #434343;
  color: #fff;
}
.Button_secondary:hover {
  background-color: #707070;
}
.Button_tertiary {
  border: 1px solid #e00000;
  color: #e00000;
  background-color: transparent;
  padding: 0.563rem 1rem;
}
.Button_tertiary:hover {
  background-color: #a90000;
  border-color: #a90000;
  color: #fff;
}
.Button:disabled {
  background-color: #eee;
  color: #5a5a5a;
}
.Button_remove {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem;
  font-size: 1rem;
  line-height: 1rem;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  color: #e00000;
}