.App {
  text-align: center;
  padding: 2rem;
  max-width: 1200px;
  margin-left: auto;
  font-family: Arial, sans-serif;
  margin-right: auto;
}

h1 {
  color: #333;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

h2 {
  color: #333;
  margin-top: 2rem;
}

p {
  color: #666;
}

.Form-Description {
  text-align: justify;
  font-weight: normal;
}

.Form-Cookie {
  align-self: flex-start;
  font-size: 18px;
}

#header{
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e02525;
}

#header .title{
margin: 0;
padding: 0 1rem;
color: #ffffff
}

.progressbar {
  /* Size */
  width: 100%;
  height: 20px;

  /* Content alignment */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  /* Style */
  border-radius: 60px;
  background-color: #494b52;
  overflow: hidden;
}

.bar {
  /* Size */
  width: 0%;

  /* Style */
  background: rgb(255, 174, 105);
  background: linear-gradient(
      180deg,
      #57DB15 0%,
      #47AF13 32%,
      #36830F 100%
  );
}

.progressbar-container {
  /* Content alignment */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progressbar-text-container {
  /* Size */
  width: 30px;
  margin-left: 16px;

  /* Content alignment */
  display: flex;
  flex-direction: row;

  /* Styling */
  color: white;
  font-family: montserrat;
  font-weight: bold;
  font-size: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid #ddd;
}

/* Estilos para las celdas de encabezado */
th {
  background-color: #f2f2f2;
  text-align: center;
  padding: 8px;
}

/* Estilos para las celdas de datos */
td {
  padding: 8px;
  text-align:left;
  white-space: pre-line;
}

/* Estilos para filas alternas (opcional) */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.TableCell {
  height: 500px;
  overflow-y: auto;
}

.TableCellHistory {
  height: 200px;
  overflow-y: auto;
}

.TableCell-Articulo {
  min-width: 350px;
}
.TableCell-Referencias {
  max-width: 300px;
}
.TableCell-Tendencias {
  max-width: 350px;
}
.TableCell-Keywords {
  max-width: 110px;
}
.Form-Select{
  margin-bottom: 16px;
  height: 30px;
  border-radius: 5px;
  max-width: 150px;
  align-self: center;
}
.ListTrends-Container{
  list-style: none;
  padding: 0;
}
.ListTrends-Item{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.ListTrends-Check{
  margin-right: 8px;
}
.ListTrends-Label{
  margin: 0;
}
.Form-TrendsItem{
  text-align: left;
}
.Form-DescriptionTrends{
  text-align: left;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #2199df;
  text-decoration: unset;
}

ul {
  margin-left: 1.5rem;
  list-style: none;
}



h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 1em 0;
}

/* general */

body {
  font-family: "Roboto", sans-serif;
}

table{
  text-align: center;
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
}

.Table-Container{
  overflow-x:auto;
  overflow-y:auto;
}

.striped{
  margin-left: -250px;
}

.striped_users {
  margin-left: auto;
}

th, td{
  border: solid 1px #c2c2c2;
}

thead{
  background-color: #e07c7c;
  border-bottom: #b91b1b;
}

td {
  vertical-align: medium;
}

.page {
  padding: 1rem;
}

.container {
  margin: 0 auto;
  max-width: 900px;
}

/* header */

#header{
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e02525;
}

#header .title{
  margin: 0;
  padding: 0 1rem;
  color: #ffffff
}

#header .auth-options{
  height: 100%;
  display: flex;
}

#header .auth-options button{
  padding: 0 1rem;
  background-color: #b91b1b;
  color: #ffffff;
  font-size: 1.1rem;
  border: none;
}

/* error notice */
.error-notice{
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}
.error-notice button{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

/* media queries */

#header .auth-options button:hover{
  background-color: #be3a3a;
}

@media screen and (max-width: 550px){
  #header .title{
    font-size: 1.2rem;
  }
  #header .auth-options button{
    padding: 0 0.5rem;
    font-size: 0.9rem; 
  }
}

.fileContent{
  height: 300px;
  overflow: auto;
  background: #EEE;
  color: #666;
}

.Forms-Container{
  margin: 0 16px;
  width: auto;
}

.s12{
  display: flex;
  column-gap: 12px;
}

.TableForms{
  width:max-content;
}
.ButtonNav{
  border-radius: 5px;
  padding: 5px;
  border: 1px #666 solid;
}

.Form-Contanier{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.Records-Container{
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.Table-Container_small{
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.ControlTable-Container_small{
  text-align-last: center;
}

.Modal {
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  place-content: center;
  height: 100%;
  background: rgba(0, 0, 0, .5);
}
.Modal-Container::-webkit-scrollbar {
  display: none;
}
.Modal-Container {
  background-color: white;
  padding: 0px 0px 16px 0px;
  margin: auto  ;
  border-radius: 14px;
  overflow: auto;
  width: 85%;
  margin-top: 50px;
  max-height: 700px;
}

.Modal-hide {
  display: none;
}
.Modal-closeSection {
  height: 100px;
  position: absolute;
  width: 100px;
  top: 0;
  right: 0;
  cursor: pointer;
}
.Modal-Icon{
  height: 15px;
  cursor: pointer;
}
.Modal-Logo{
  height: 25px;
}
.Modal-Button{
  background-color: #1c262c;
  border: 1px solid black;
  border-radius: 7px;
  margin-top: 20px;
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.Modal-Button:hover{
  background-color: #df4343;
  transition: 0.3s;
}
.Modal-HeaderContainer{
  color: white;
  z-index: 1;
  padding-top: 16px;
  background-color: #1c262c;
  position: sticky;
  top:0;
  display: flex;
  place-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 32px;
}

.btn-closeForm{
  color:white;
  text-decoration: none;
  padding-right: 16px;
}

.Modal-ModalButton{
  position: sticky;
  top: 100%;
  text-align: center;
  background-color: white;
}

.Modal-ModalContent{
  padding: 16px 16px 16px 16px;
}

.card{
  width: 110%;
}